import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,8,9,11,12,3,4];

export const dictionary = {
		"/(website-redesign)": [29,[7]],
		"/(website-redesign)/about-us": [30,[7]],
		"/admin": [35,[8]],
		"/admin/projects": [~36,[8]],
		"/admin/proposals": [37,[8]],
		"/admin/templates": [~38,[8]],
		"/admin/templates/[templateID]": [39,[8]],
		"/admin/templates/[templateID]/[templateVersion]": [40,[8,9]],
		"/admin/templates/[templateID]/[templateVersion]/[sectionID]": [~41,[8,9,10]],
		"/admin/users": [42,[8]],
		"/admin/users/[userID]": [43,[8,11]],
		"/admin/users/[userID]/[profileID]": [44,[8,11,12]],
		"/(authenticated)/applications/[proposalID]": [17,[3,4]],
		"/(authenticated)/applications/[proposalID]/edit": [~18,[3,4]],
		"/(authenticated)/applications/[proposalID]/export": [19,[3,4]],
		"/(authenticated)/applications/[proposalID]/review": [~20,[3,4]],
		"/(authenticate-account)/challenge": [13,[2]],
		"/(authenticate-account)/confirmation": [14,[2]],
		"/(website-redesign)/contact-us": [31,[7]],
		"/(authenticated)/grant-projects": [21,[3]],
		"/(authenticated)/grant-projects/[projectID]": [~22,[3]],
		"/(authenticated)/grants": [23,[3,5]],
		"/(authenticated)/grants/[grantID]": [~24,[3,5]],
		"/(authenticated)/grants/[grantID]/write": [25,[3,5]],
		"/(authenticated)/help": [26,[3]],
		"/(authenticate-account)/login": [15,[2]],
		"/(authenticated)/onboarding": [27,[6]],
		"/(website-redesign)/pricing": [32,[7]],
		"/(website-redesign)/privacy-policy": [33,[7]],
		"/(authenticated)/settings": [28,[3]],
		"/(authenticate-account)/signup": [16,[2]],
		"/(website-redesign)/terms": [34,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';